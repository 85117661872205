<template>
  <div>
    <AddressFormGroup v-on="$listeners" />

    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import AddressFormGroup from 'chimera/moving/components/form/parts/address/AddressFormGroup'
import { movingConsumerNL } from 'chimera/moving/service'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'

export default {
  name: 'MovingFromAddressFormPart',

  components: {
    AddressFormGroup,
    ServiceField,
  },

  /**
   */
  mounted() {
    const country = this.$store.getters['context/get']('country', 'nl')
    if (country === 'nl') {
      // Set default service to `moving-consumer`.
      this.$refs.service.setValue(movingConsumerNL.id)
    }
  },
}
</script>
