<template>
  <FormModal title="Verhuizen van" show-back-btn>
    <template #body>
      <MovingFromFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import MovingFromFormStep from '~/components/form/steps/movingFrom/MovingFromFormStep'

export default {
  components: {
    MovingFromFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Verhuizen van',
      headDescription: 'Geef aan waar waar je vandaan gaat verhuizen.',
      path: '/offertes-aanvragen/verhuizen-van',
      progressValue: 20,
      checkoutStep: 1,
    }
  },
}
</script>
